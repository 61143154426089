.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.spinner {
  display: inline-block;
  position: relative;
  width: 96px;
  height: 96px;
}

.dot1,
.dot2 {
  position: absolute;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #01683b;
  animation: scale 1s ease-in-out infinite;
}

.dot2 {
  animation-delay: -0.5s;
}

@keyframes scale {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
