.each-slide-effect > div {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  height: 45vh;
  z-index: 0;
}

.each-slide-effect span {
  padding: 2px;
  font-size: 20px;
  background: hsla(150, 2%, 44%, 0.5);
  text-align: end;
  color: white;
  z-index: 0;
}

.content {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.pageImageLinks:hover {
  opacity: 0.8;
}
