@font-face {
  font-family: GolosText-Regular;
  src: url(../fonts/GolosText-Regular.ttf);
}

@font-face {
  font-family: GolosText-Bold;
  src: url(../fonts/GolosText-Bold.ttf);
}

.navItemSelected {
  color: #ce4529;
  text-decoration: none;
  font-family: "GolosText-Bold", Arial, Helvetica, sans-serif;
  font-size: 18pt;
  z-index: 5;
}
.navItemSelected:hover {
  color: #ce4529;
  text-decoration: none;
  z-index: 5;
}

.navItem {
  color: #093823;
  text-decoration: none;
  font-family: "GolosText-Bold", Arial, Helvetica, sans-serif;
  font-size: 18pt;
  z-index: 5;
}
.navItem:hover {
  color: #ce4529;
  animation: fadein 0.4s;
  -moz-transition: fadein 0.4s;
  -o-transition: fadein 0.4s;
  -webkit-transition: fadein 0.4s;
  z-index: 5;
}

/* KeyFrames for color fading nav items on hover */
@keyframes fadein {
  from {
    color: black;
  }
  50% {
    color: rgb(118, 118, 118);
  }
  to {
    color: #ce4529;
  }
}

@-moz-keyframes fadein {
  /* Firefox */
  from {
    color: black;
  }
  50% {
    color: rgb(118, 118, 118);
  }
  to {
    color: #ce4529;
  }
}

@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    color: black;
  }
  50% {
    color: rgb(118, 118, 118);
  }
  to {
    color: #ce4529;
  }
}

@-o-keyframes fadein {
  /* Opera */
  from {
    color: black;
  }
  50% {
    color: rgb(118, 118, 118);
  }
  to {
    color: #ce4529;
  }
}

.dropdownMenu {
  display: flex;
  flex-direction: column;
  position: absolute;
  font-family: "Arial", Arial, Helvetica, sans-serif;
  padding-left: 0.45em;
  padding-right: 0.45em;
  z-index: 5;
  background-color: rgba(239, 252, 239, 0.8);
  font-family: "GolosText-Bold";
  font-size: 18pt;
}

.navLink {
  padding-left: 0.45em;
  padding-right: 0.45em;
  z-index: 5;
  cursor: pointer;
  user-select: none;
  font-family: "GolosText-Bold";
  font-size: 18pt;
  color: #093823;
}

.navLink:hover {
  color: #ce4529;
  animation: fadein 0.4s;
  -moz-transition: fadein 0.4s;
  -o-transition: fadein 0.4s;
  -webkit-transition: fadein 0.4s;
  z-index: 5;
}

.selectedNavLink {
  padding-left: 0.45em;
  padding-right: 0.45em;
  z-index: 5;
  cursor: pointer;
  user-select: none;
  font-family: "GolosText-Bold";
  font-size: 18pt;
  color: #ce4529;
}

.dropdownItem {
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 5;
  font-size: 18pt;
  font-family: "GolosText-Bold";
}

.socialIcon{
  color:#EFFCEF
}


.socialIcon:hover{
  color: #ce4529;
}