.radix-link {
  cursor: pointer;
  text-decoration: none;
  font-family: "GolosText-Bold";
  color: white;
}

.radix-link:hover {
  color: #ce4529;
  cursor: pointer;
  text-decoration: none;
  font-family: "GolosText-Bold";
}
