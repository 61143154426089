@font-face {
  font-family: GolosText-Regular;
  src: url(../fonts/GolosText-Regular.ttf);
}

@font-face {
  font-family: GolosText-Bold;
  src: url(../fonts/GolosText-Bold.ttf);
}

.stsfHeaders {
  font-family: "GolosText-Bold";
  color: #01683b;
  margin: 0;
}

.stsfBody {
  font-family: "GolosText-Regular";
  color: #01683b;
  margin: 0;
  font-size: 13pt;
  line-height: 1.75;
}

.stsfMini {
  font-family: "GolosText-Regular";
  color: #01683b;
  margin: 0;
  font-size: 12pt;
}

.stsfMiniHeader {
  font-family: "GolosText-Bold";
  color: #01683b;
  margin: 0;
  font-size: 13pt;
  line-height: 1.25;
}
