.Collapsible__trigger {
  background-color: rgb(133, 133, 133);
  cursor: pointer;
}

.ClickableElement {
  cursor: pointer;
  text-decoration: none;
  font-family: "GolosText-Bold";
  color: #01683b;
}

.ClickableElement:hover {
  color: #ce4529;
  cursor: pointer;
  text-decoration: none;
  font-family: "GolosText-Bold";
}
